import antiqueBg from "./antique-bg.webp";
import artBg from "./art-bg.webp";
import carpetBg from "./carpet-bg.webp";
import handcraftBg from "./handcraft-bg.webp";
import jewelryBg from "./jewelry-bg.webp";

export const MarketPlaces: any = [
  {
    name: "Art",
    slogun: "The world of art, in the frame of eternity",
    discription: "Ignite your Passion, Collect with Purpose ",
    marketTitle: "Curated Artworks",
    background: artBg,
    link: "/art/home",
    videoUrl:
      "https://embed-ssl.wistia.com/deliveries/d7c27f66a5daefe3416ea16346d18721411ce19a.bin?disposition=attachment&filename=Art+Website+Promo.mp4",
    footerUrl: "66c072a10c63be0b7177619c",
    sloganSvg: [],
  },
  {
    name: "Jewellery",
    slogun: "A Symphony of Brilliance, from Emeralds to Eternity",
    discription: "Jewelry that Captures the Soul of a Gem",
    marketTitle: "",
    background: jewelryBg,
    link: "/jewellery/home",
    videoUrl: "",
    footerUrl: "66c071b20c63be0b7177619a",
    sloganSvg: [],
  },

  {
    name: "Carpet",
    slogun: "Authentic silk Rugs",
    discription: "Own a piece of art, woven with stories",
    marketTitle: "Curated Artworks",
    background: carpetBg,
    link: "/carpet/home",
    videoUrl:
      "https://embed-ssl.wistia.com/deliveries/612604577aa92d98f9ddf62d8b42a496.bin?disposition=attachment&filename=Carpet+V2",
    footerUrl: "66c076e30c63be0b717761a0",
    sloganSvg: [],
  },
  {
    name: "Antique",
    slogun: "Own a piece of history",
    discription: "Treasures that last a lifetime. ",
    marketTitle: "Curated Artworks",
    background: antiqueBg,
    link: "/antique/home",
    videoUrl: "",
    footerUrl: "66c0715a0c63be0b71776196",
    sliders: [
      {
        file: "6628ee115b8f32ae02df2ce7",
        slogan:
          "Own a piece of history.<br/>Find antiques you'll <br/>love on Artogenia. ",
        device: "desktop",
      },
      {
        file: "6628ee305b8f32ae02df2ce9",
        slogan:
          "Invest in timeless<br/>beauty. Shop antiques<br/>on Artogenia.",
        device: "desktop",
      },
      {
        file: "6628ee3c5b8f32ae02df2ceb",
        slogan:
          "Treasures that last a<br/>lifetime. Discover <br/>antiques on Artogenia.",
        device: "desktop",
      },
      {
        file: "6628ef865b8f32ae02df2cf3",
        slogan:
          "Own a piece of history.<br/>Find antiques you'll <br/>love on Artogenia. ",
        device: "mobile",
      },
      {
        file: "6628ef935b8f32ae02df2cf5",
        slogan:
          "Invest in timeless<br/>beauty. Shop antiques<br/>on Artogenia.",
        device: "mobile",
      },
      {
        file: "6628ef5c5b8f32ae02df2cef",
        slogan:
          "Treasures that last a<br/>lifetime. Discover <br/>antiques on Artogenia.",
        device: "mobile",
      },
    ],
  },
  {
    name: "Handcraft",
    slogun: "Made with Love, Found on Artogenia",
    discription: "Ignite your Passion, Collect with Purpose ",
    marketTitle: "",
    background: handcraftBg,
    link: "/handcraft/home",
    videoUrl: "",
    footerUrl: "66c0710a0c63be0b71776194",
    sliders: [
      {
        file: "662ca11e5b8f32ae02df2d13",
        slogan: "Made with love,<br/> found on Artogenia.",
        device: "desktop",
      },
      {
        file: "662ca1325b8f32ae02df2d15",
        slogan: "Handcrafted<br/>treasures, waiting<br/> to be discovered.",
        device: "desktop",
      },
      {
        file: "662ca14d5b8f32ae02df2d17",
        slogan:
          "Own a piece of <br/>passion. Explore<br/>handcrafts on <br/>Artogenia.",
        device: "desktop",
      },
      // mobile
      {
        file: "662e52785b8f32ae02df2d35",
        slogan: "Made with love,<br/> found on Artogenia.",
        device: "mobile",
      },
      {
        file: "662e52965b8f32ae02df2d37",
        slogan: "Handcrafted<br/>treasures, waiting<br/> to be discovered.",
        device: "mobile",
      },
      {
        file: "662e52aa5b8f32ae02df2d39",
        slogan:
          "Own a piece of <br/>passion. Explore<br/>handcrafts on <br/>Artogenia.",
        device: "mobile",
      },
    ],
  },
  {
    name: "Academy",
    slogun:
      "Unlock Your Potential: Explore Our Courses for a Brighter Tomorrow",
    discription: "Ignite your Passion, Collect with Purpose ",
    marketTitle: "",
    background: handcraftBg,
    link: "/handcraft/home",
    videoUrl:
      "https://embed-ssl.wistia.com/deliveries/39dbc98364d5bae4876721e95e147164.bin?disposition=attachment&filename=academia",
    sloganSvg: [],
    footerUrl: "66c070c60c63be0b71776192",
  },
];
