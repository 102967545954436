import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { biconomyContext } from "../../utils/context";
import { useGetProfileDetailQuery } from "../../store/api/api";
import { useSelector } from "react-redux";
import { alrtProgessProfile, successProgerssProfile } from "../../assets";
import { Link } from "react-router-dom";

const ProfileProgress = ({ setClose }: { setClose?: any }) => {
  const userId = useSelector<any>((state) => state.auth.userId);
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const { walletAddress } = useContext(biconomyContext);
  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  // Function to enable scroll
  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };
  const { data, refetch: refetchProfile } = useGetProfileDetailQuery(
    {
      identifier: walletAddress?.toLowerCase(),
    },
    {
      skip: Boolean(!accessToken),
    }
  );
  const [progress, setProgress] = useState(0);
  const [aboutText, setAboutText] = useState("");
  console.log("🚀 ~ ProfileProgress ~ aboutText:", aboutText);
  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data?.aboutMe, "text/html");

    const extractedText = doc.body.textContent || "";
    setAboutText(extractedText);
    let newProgress = 0;
    if ((data?.firstName + data?.lastName + "")?.length > 1) {
      newProgress += 25;
    }
    if (data?.headline?.length > 1) {
      newProgress += 25;
    }
    if (data?.contactInfo?.phones[0]?.phone?.length > 1) {
      newProgress += 25;
    }
    if (extractedText?.length > 10) {
      newProgress += 25;
    }
    setProgress(newProgress);
  }, [data, userId]);
  useEffect(() => {
    refetchProfile();
  }, []);

  if (!accessToken) {
    return null;
  }
  if (
    (data?.firstName + data?.lastName + "")?.length > 1 &&
    data?.headline?.length > 1 &&
    data?.contactInfo?.phones[0]?.phone?.length > 1 &&
    aboutText?.length > 10
  ) {
    return null;
  } else {
    return (
      <div style={{backdropFilter:'blur(10px)'}} className="profileContainer-progress">
        <div className="profile-progress">
          {setClose ? (
            <button
              style={{ marginLeft: "auto" }}
              onClick={() => {
                setClose(false);
                enableScroll();
              }}
            >
              {" "}
              &times;
            </button>
          ) : null}
          <h2>Complete Profile!</h2>
          <p>
            To upload and mint your artworks, please complete your{" "}
            <Link
              to={`/profile/${
                data?.username?.length > 0
                  ? data?.username
                  : data?.publicKeys[0]?.key
              }`}
            >
              profile.
            </Link>
          </p>
          <div className="status-container">
            <div className="status-column">
              <div className="status-item">
                <img
                  src={
                    (data?.firstName + data?.lastName + "")?.length > 1
                      ? successProgerssProfile
                      : alrtProgessProfile
                  }
                />
                Name
              </div>
              <div className="status-item">
                <img
                  src={
                    data?.headline?.length > 1
                      ? successProgerssProfile
                      : alrtProgessProfile
                  }
                />
                Field
              </div>
              <div className="status-item">
                <img
                  src={
                    data?.contactInfo?.phones[0]?.phone?.length > 1
                      ? successProgerssProfile
                      : alrtProgessProfile
                  }
                />
                Phone Number
              </div>
              <div className="status-item">
                <img
                  src={
                    aboutText?.length > 10
                      ? successProgerssProfile
                      : alrtProgessProfile
                  }
                />
                Biography
              </div>
            </div>
          </div>
          <div className="progress-bar-container">
            <div className="circleProgerss-percentage">
              <div></div>
            </div>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <span className="progress-percentage">{progress}%</span>
          </div>
          <button className="payment-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="24"
              viewBox="0 0 32 24"
              fill="none"
            >
              <path
                d="M13.0896 22.2886C14.6108 24.2646 17.5908 24.2646 19.1119 22.2886L31.1365 6.66818C33.0601 4.16942 31.2788 0.5502 28.1254 0.5502L4.07618 0.550196C0.92279 0.550195 -0.85852 4.16941 1.06503 6.66817L13.0896 22.2886Z"
                fill="#0F62FE"
              />
            </svg>
            Upload Artworks
          </button>
        </div>
      </div>
    );
  }
};

export default ProfileProgress;
